import Vue from 'vue'
import VueRouter from 'vue-router'
import Help from '../views/Help.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/Help',
    name: 'Help',
    component: Help
  },
  {
    path: '/new',
    name: 'New',
    component: () => import(/* webpackChunkName: "about" */ '../views/New.vue')
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Edit.vue')
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path: '/invite/:uuid',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/InviteForm.vue')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "about" */ '../views/Thanks.vue')
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/Passwords/ForgotPassword.vue')
  },
  {
    path: '/Password/Edit',
    name: 'PasswordEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Passwords/ResetPassword.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
