<template>
  <div>
    <header class="menu">
      <nav class="navbar fixed-top">
        <span v-b-toggle.sidebar-left class="side-menu-icon"
          ><img src="@/assets/menu.svg" alt="" class="img-fluid"
        /></span>
        <!-- <a href="javascript:void(0);" class="navbar-brand">Meetings</a> -->
        <img src="@/assets/logo.png" alt="" class="img-fluid center logo"
        />
        <router-link :to="{ name: 'Help'}"  class="help-btn1">Help<img src="@/assets/play.svg" class="ml-1" alt=""></router-link>
      </nav>
    </header>

    <!-- Left Menu Sidebar -->
    <b-sidebar
      id="sidebar-left"
      :title="RoleName"
      bg-variant="white"
      backdrop
      shadow
    >
      <template #footer="">
        <span class="text-center d-block"
          >{{userEmail}}</span
        >
        <div class="d-flex bg-white align-items-center px-3 py-2">
          <a href="javascript:void(0);" class="navbar-brand" @click="logout">Logout</a>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  created(){
    this.$store.dispatch('setActiveTitle', "Options")
    // console.log(this.$route)
  },
  methods: {
    logout(){
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.$axios.delete('logout')
            .then(res => {
              if(res.data.status == true){
                localStorage.removeItem('empanelment-crm-token');
                localStorage.removeItem('empanelment-user-name');
                localStorage.removeItem('empanelment-user-id');
                localStorage.removeItem('empanelment-user-email');
                localStorage.removeItem('empanelment-crm-role_id');
                localStorage.removeItem('empanelment-crm-role_name');
                this.$router.replace('/login');
                return;
              }
            })
            .catch(err => console.log(err));
        }
      });
    }
  },
  computed: {
    RoleName(){
      return localStorage.getItem('radiate-crm-role_name')
    },
    userEmail(){
      return localStorage.getItem('radiate-user-email')
    }
  }
}
</script>
<style>
.menu nav.fixed-top {
  /* background: linear-gradient(
300.41deg
, #f58322 54.02%, #ffa151 114.97%); */
  box-shadow: none;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logo.center{
  max-width: 146px;
}

.b-sidebar > .b-sidebar-header {
  padding: 1rem  !important;
}
</style>