<template>
      <div class="">
        <div class="inner-header  ">
            <nav class="navbar fixed-top bg-white animate__animated animate__slideInRight">
                <a @click="$router.go(-1)" class="back-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.368" height="16.365" viewBox="0 0 17.368 16.365">
                        <g id="Group_38360" data-name="Group 38360" transform="translate(-27.632 -34.814)" opacity="1">
                            <line id="Line_124" data-name="Line 124" x2="16" transform="translate(29 43)" fill="none"
                                stroke="#000" stroke-width="2" />
                            <path id="Path_51451" data-name="Path 51451" d="M.033,0l-7,7.425L.033,15"
                                transform="translate(35.967 35.5)" fill="none" stroke="#000" stroke-width="2" />
                        </g>
                    </svg>
                </a>
                <a href="javascript:void(0);" class="link-title center">Help</a>
            </nav>
        </div>
        <div class="inner-page bg-white animate__animated animate__slideInRight">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card details-card form-card">
                            <div class="card-body p-0 mt-5">
                                <div class="row">
                                    <div class="container">
                                      <my-video :sources="video.sources" :options="video.options"></my-video>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import myVideo from 'vue-video'
export default {
  data () {
    return {
      campaign_video: '',
      meeting_video: '',
      video: {
      sources: [{
        src: 'https://s3.ap-south-1.amazonaws.com/pistol.temp/videos/Syndicate/Mobile+UI+tools+-+Syndicate/EMPANELMENT+WEB+APP.mp4',
        type: 'video/mp4'
      }],
        options: {
          autoplay: true,
          volume: 0.6,
        }
      }
    }
  },
  components: {
      myVideo
  },
}
</script>

<style>

</style>