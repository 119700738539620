<template>
  <div>
    <the-header />
    <div class="animationFix">
      <slot />
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../components/Common/TheHeader';
import TheFooter from '../components/Common/TheFooter';
export default {
  components: {
    TheHeader,
    TheFooter
  },
  created() {
    let rmList = []
    let cities = []
    let reraStatus = []
    let Localities = []
    let fieldExecutive = []
    let paymentModes = []
    let countryCodes = []
    this.$axios.get('brokers/settings')
      .then(res => {
        console.log(res,"res")
        res.data.city_data.forEach((item,index) => {
          cities.push({value: item.id, text: item.name})
        });
        this.$store.dispatch('setCity', cities);

        res.data.countries.forEach((item,index) => {
          countryCodes.push({value: item.iso_code, text: item.iso_code})
        });
        this.$store.dispatch('setCountryCodes', countryCodes);

        res.data.rera_status.forEach((item,index) => {
          reraStatus.push({value: item, text: item})
        });
        reraStatus.unshift({value: null, text: 'Select ReraStatus'});
        this.$store.dispatch('setReraStatus', reraStatus);

        res.data.executive_data.forEach((item,index) => {
          fieldExecutive.push({value: item.id, text: item.full_name})
        });
        this.$store.dispatch('setFieldExecutives', fieldExecutive);

        res.data.rm_data.forEach((item,index) => {
          rmList.push({value: item.id, text: item.full_name})
        });
        rmList.unshift({value: null, text: 'Select RM'});
        this.$store.dispatch('setRm', rmList);

        res.data.localities_data.forEach((item,index) => {
          Localities.push({value: item.id, text: item.display_name})
        });
        Localities.unshift({value: null, text: 'Select Localities'});
        this.$store.dispatch('setLocalities', Localities);
        res.data.payment_modes.forEach((item,index) => {
          paymentModes.push({value: item, text: item})
        });
        this.$store.dispatch('setPaymentModes', paymentModes);
      })
      .catch(err => console.log(err.response))
  }
}
</script>